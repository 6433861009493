import { useEffect } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { ACTIVITY_EVENTS } from '../constants/Constants';
import useLocalStorage from './useLocalStorage';

const logoutChannel = new BroadcastChannel('logout');

const useIdle = (options) => {
  const [status, setStatus] = useLocalStorage('status', {
    isIdle: 'false',
    lastActive: new Date().getTime(),
    timeoutId: null,
  });

  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      const context = this;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }

  const updateActiveStatus = () => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      isIdle: 'false',
      lastActive: new Date().getTime(),
    }));
  };

  const debouncedUpdateLocalStorage = debounce(updateActiveStatus, 5000);

  const handleActive = () => {
    debouncedUpdateLocalStorage();
  };

  useEffect(() => {
    let timeoutId = status?.timeoutId;
    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (
          !localStorage.getItem('remember_me') &&
          localStorage.getItem('access_token') &&
          localStorage.getItem('refresh_token')
        ) {
          setStatus((prevStatus) => ({
            ...prevStatus,
            isIdle: 'true',
            lastActive: new Date().getTime(),
          }));
        }
      }, options.timeToIdle);
      setStatus((prevStatus) => ({
        ...prevStatus,
        timeoutId,
      }));
      localStorage.setItem('timeoutId', timeoutId);
    };

    ACTIVITY_EVENTS.forEach((event) => {
      window.addEventListener(event, () => {
        handleActive();
        resetTimeout();
      });
    });

    resetTimeout();

    ACTIVITY_EVENTS.forEach((event) => {
      window.addEventListener(event, handleActive);
    });

    return () => {
      ACTIVITY_EVENTS.forEach((event) => {
        window.removeEventListener(event, handleActive);
      });
      clearTimeout(timeoutId);
      setStatus((prevStatus) => ({
        ...prevStatus,
        timeoutId: null,
      }));
      localStorage.removeItem('status');
      localStorage.removeItem('timeoutId');
    };
  }, []);

  useEffect(() => {
    window.addEventListener('storage', (event) => {
      if (event.key === 'status') {
        setStatus(JSON.parse(event.newValue));
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener('storage', (event) => {
      if (event.key === 'timeoutId') {
        const storedTimeoutId = event.newValue;
        if (storedTimeoutId !== status?.timeoutId) {
          clearTimeout(status?.timeoutId);
          setStatus((prevStatus) => ({
            ...prevStatus,
            timeoutId: storedTimeoutId,
          }));
        }
      }
    });
  }, [status.timeoutId]);

  return { status, logoutChannel };
};

export default useIdle;
