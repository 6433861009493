import Keycloak from 'keycloak-js';

const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL;
const realm = process.env.REACT_APP_REALM_NAME;
const clientId = process.env.REACT_APP_CLIENT_ID;
const keycloak = new Keycloak({
  url: keycloakUrl,
  realm,
  clientId,
});

export default keycloak;
