import React, { useState, useRef, useImperativeHandle } from 'react';
import {
  Menu,
  MenuProps,
  MenuItem,
  MenuItemProps,
  styled,
  Typography,
} from '@mui/material';
import { CaretRight } from 'phosphor-react';
import { colors } from '../../constants/Constants';

const NavMenu = styled(Menu)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '18px',
  color: theme.colors.appInputLabel,
}));

const NavMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const NavMenuTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '18px',
  color: theme.colors.appInputLabel,
  width: '115px',
}));

export interface INestedMenuItemProps extends Omit<MenuItemProps, 'button'> {
  parentMenuOpen: boolean;
  label?: React.ReactNode;
  rightIcon?: React.ReactNode;
  ContainerProps?: React.HTMLAttributes<HTMLElement> &
    React.RefAttributes<HTMLElement | null>;
  MenuProps?: Omit<MenuProps, 'children'>;
  button?: true | undefined;
}

const NestedMenuItem = React.forwardRef<
  HTMLLIElement | null,
  INestedMenuItemProps
>((props, ref) => {
  const {
    parentMenuOpen,
    label,
    rightIcon = (
      <CaretRight
        style={{ marginLeft: '11px' }}
        size={10}
        color={colors.appInputPlaceHolder}
      />
    ),
    children,
    className,
    tabIndex: tabIndexProp,
    ContainerProps: ContainerPropsProp = {},
    ...NestedMenuItemProps
  } = props;

  const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

  const menuItemRef = useRef<HTMLLIElement>(null);
  useImperativeHandle(ref, () => menuItemRef.current);

  const containerRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(containerRefProp, () => containerRef.current);

  const menuContainerRef = useRef<HTMLDivElement>(null);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setIsSubMenuOpen(true);

    if (ContainerProps?.onMouseEnter) {
      ContainerProps.onMouseEnter(event);
    }
  };
  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    setIsSubMenuOpen(false);

    if (ContainerProps?.onMouseLeave) {
      ContainerProps.onMouseLeave(event);
    }
  };

  const isSubmenuFocused = () => {
    const active = containerRef.current?.ownerDocument?.activeElement;
    const allChildren = menuContainerRef.current?.children ?? [];
    for (const child of allChildren) {
      if (child === active) {
        return true;
      }
    }
    return false;
  };

  const handleFocus = (event: React.FocusEvent<HTMLElement>) => {
    if (event.target === containerRef.current) {
      setIsSubMenuOpen(true);
    }

    if (ContainerProps?.onFocus) {
      ContainerProps.onFocus(event);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      return;
    }

    if (isSubmenuFocused()) {
      event.stopPropagation();
    }

    const active = containerRef.current?.ownerDocument?.activeElement;

    if (event.key === 'ArrowLeft' && isSubmenuFocused()) {
      containerRef.current?.focus();
    }

    if (
      event.key === 'ArrowRight' &&
      event.target === containerRef.current &&
      event.target === active
    ) {
      const firstChild = menuContainerRef.current?.children[0] as
        | HTMLElement
        | undefined;
      firstChild?.focus();
    }
  };

  const open = isSubMenuOpen && parentMenuOpen;
  let tabIndex;
  if (!props.disabled) {
    tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
  }

  return (
    <div
      {...ContainerProps}
      ref={containerRef}
      onFocus={handleFocus}
      tabIndex={tabIndex}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
      role="button"
    >
      <NavMenuItem
        {...NestedMenuItemProps}
        className={className}
        ref={menuItemRef}
      >
        <NavMenuTypo>{label}</NavMenuTypo>
        {rightIcon}
      </NavMenuItem>
      <NavMenu
        style={{ pointerEvents: 'none' }}
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={() => {
          setIsSubMenuOpen(false);
        }}
      >
        <div ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
          {children}
        </div>
      </NavMenu>
    </div>
  );
});

export default NestedMenuItem;
