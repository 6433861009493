import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from '@mui/material';
import circleWarning from '../../assets/images/circle-warning.svg';

interface IWarningDialog {
  open: boolean;
  handleClose: () => void;
  handleConfirm?: (value: string | void) => void;
  message: string;
  title: string;
  type?: string;
  cancelText?: string;
  confirmText?: string;
}
const DialogContainer = styled('div')(() => ({
  width: '544px',
  height: '196px',
  padding: '24px',
}));
const DialogWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: '32px',
}));

const DialogHeading = styled('span')(({ theme }) => ({
  fontWeight: '600',
  fontSize: '18px',
  lineHeight: '28px',
  color: theme.colors.appTitle,
}));

const DialogText = styled('span')(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
  color: theme.colors.appInputLabel,
}));

const CancelButton = styled('div')(({ theme }) => ({
  width: '79px',
  height: '44px',
  border: `1px solid ${theme.colors.appInputBorder}`,
  background: theme.colors.appWhite,
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}));

const ConfirmButton = styled(CancelButton)(({ theme }) => ({
  width: '135px',
  background: theme.colors.appWarningYellow,
}));

const CancelButtonTypography = styled('span')(({ theme }) => ({
  color: theme.colors.appInputLabel,
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '24px',
}));

const ConfirmButtonTypography = styled(CancelButtonTypography)(({ theme }) => ({
  color: theme.colors.appWhite,
}));

const WarningDialog: React.FC<IWarningDialog> = ({
  open,
  handleClose,
  handleConfirm,
  message,
  title,
  type,
  cancelText,
  confirmText,
}) => (
  <>
    <Dialog
      PaperProps={{ style: { borderRadius: '20px' } }}
      open={open}
      onClose={handleClose}
    >
      <DialogContainer>
        <DialogWrapper>
          <img src={circleWarning} alt="delete" />
          <div style={{ marginLeft: '16px' }}>
            <DialogTitle sx={{ padding: '0px' }}>
              <DialogHeading>{title}</DialogHeading>
            </DialogTitle>
            <DialogContent sx={{ padding: '0px' }}>
              <DialogContentText sx={{ padding: '0px' }}>
                <DialogText>{message}</DialogText>
              </DialogContentText>
            </DialogContent>
          </div>
        </DialogWrapper>
        <DialogActions sx={{ padding: '0px' }}>
          {cancelText && (
            <CancelButton onClick={() => handleClose()}>
              <CancelButtonTypography>{cancelText}</CancelButtonTypography>
            </CancelButton>
          )}
          {confirmText && (
            <ConfirmButton onClick={() => handleConfirm()} autoFocus>
              <ConfirmButtonTypography>{confirmText}</ConfirmButtonTypography>
            </ConfirmButton>
          )}
        </DialogActions>
      </DialogContainer>
    </Dialog>
  </>
);

export default WarningDialog;
