import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from '../constants/Constants';

const MuiTheme = ({ children }) => {
  const theme = createTheme({
    typography: {
      fontFamily: ['Roboto'].join(','),
    },
    colors,
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiTheme;
