import { useContext } from 'react';
import AuthContext from '../contexts/AuthContext';
import { assertContextScope } from '../utils/ContextUtils';

const useAuthContext = () => {
  const context = useContext(AuthContext);
  assertContextScope(context, 'AuthContext', 'useAuthContext');
  return {
    isAuthenticated: context[0],
    setIsAuthenticated: context[1],
    setAuthProgress: context[2],
    isAuthInProgress: context[3],
    userDetails: context[4],
    setUserDetails: context[5],
  };
};

export default useAuthContext;
