import { useContext } from 'react';
import LoadingContext from '../contexts/LoadingContext';
import { assertContextScope } from '../utils/ContextUtils';

const useLoadingContext = () => {
  const context = useContext(LoadingContext);
  assertContextScope(context, 'LoadingContext', 'useLoadingContext');
  return {
    isLoading: context[0],
    setIsLoading: context[1],
  };
};

export default useLoadingContext;
