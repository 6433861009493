import { BroadcastChannel } from 'broadcast-channel';
import jwt_decode from 'jwt-decode';
import { axiosAuthInstance, axiosInstance } from '../configs/AxiosInstance';
import { HttpStatus } from '../constants/Constants';

class AuthService {
  loginBroadCastChannel: any;

  constructor(loginBroadCastChannel) {
    this.loginBroadCastChannel = loginBroadCastChannel;
  }

  getAcessToken = (): string | null => {
    const access_token = localStorage.getItem('access_token');
    return access_token;
  };

  setTokens = (data: any, rememberMe: boolean) => {
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);
    if (rememberMe) {
      localStorage.setItem('remember_me', 'true');
    }
  };

  authenticatedIdpUser = (access_token?: string | null) => {
    if (!access_token) {
      access_token = this.getAcessToken();
    }
    if (access_token) {
      const decoded = jwt_decode(access_token);
      if (decoded?.name && decoded?.email) {
        return {
          username: decoded.name,
          email: decoded.email,
        };
      }
    }
    return {};
  };

  authenticatedUser = async (): Promise<any> => {
    try {
      const access_token = this.getAcessToken();
      if (access_token) {
        const resposne = await axiosInstance.get('/user/', {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Access-Control-Allow-Origin': 'http://localhost:8080',
          },
        });
        if (resposne.response) {
          return resposne.response;
        }
        return resposne;
      }
      return {
        status: HttpStatus.UN_AUTHORIZED,
      };
    } catch (err) {
      return err;
    }
  };

  login = async (data: any, rememberMe: boolean): Promise<any> => {
    try {
      const response = await axiosAuthInstance.post('user/login', data);
      if (response.status === HttpStatus.OK) {
        this.setTokens(response.data, rememberMe);
      }
      return response;
    } catch (err) {
      return err.response;
    }
  };

  loginNotificationToAllTabs = () => {
    this.loginBroadCastChannel.postMessage('login');
  };

  hardNavigate = (path: string) => {
    window.location.href = path;
  };

  loginListnerForAllTabs = () => {
    this.loginBroadCastChannel.onmessage = (e) => {
      window.location.reload();
      this.loginBroadCastChannel.close();
    };
  };

  register = async (data: any): Promise<any> => {
    try {
      const response = await axiosAuthInstance.post('/user/', data);
      return response;
    } catch (err) {
      return err.response;
    }
  };

  refreshTokens = async (data: any): Promise<any> => {
    try {
      const response = await axiosAuthInstance.post(
        '/user/refresh-tokens',
        data
      );
      return response;
    } catch (err) {
      return err;
    }
  };

  verifyEmail = async (actionToken: any): Promise<any> => {
    try {
      const response = await axiosAuthInstance.post('/user/verify-email', {
        token: actionToken,
      });
      return response;
    } catch (err) {
      return err.response;
    }
  };

  resendEmail = async (data: any): Promise<any> => {
    try {
      const response = await axiosAuthInstance.post('/user/resend-email', data);
      return response;
    } catch (err) {
      return err.response;
    }
  };

  forgotPassword = async (data: any): Promise<any> => {
    try {
      const response = await axiosAuthInstance.post(
        '/user/forgot-password',
        data
      );
      return response;
    } catch (err) {
      return err.response;
    }
  };

  resetPassword = async (data: any): Promise<any> => {
    try {
      const response = await axiosAuthInstance.put(
        '/user/reset-password',
        data
      );
      return response;
    } catch (err) {
      return err.response;
    }
  };

  updatePassword = async (data: {
    oldPassword: string;
    newPassword: string;
  }) => {
    try {
      const response = await axiosInstance.put('/user/update-password', data);
      return response;
    } catch (err) {
      return err.response;
    }
  };
}
const loginBroadCastChannel = new BroadcastChannel('login');
const authService = new AuthService(loginBroadCastChannel);
export default authService;
