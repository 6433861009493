import { styled } from '@mui/material';
import { SignOut } from 'phosphor-react';

const Loader = styled('img')(() => ({
  width: '50px',
  height: '50px',
  animation: 'rotation 2s infinite linear',
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

interface ISignOutIconProps {
  pl?: number;
}
const SignOutIcon = styled(SignOut)<ISignOutIconProps>(({ pl }) => ({
  paddingLeft: `${pl}px`,
}));

export { Loader, SignOutIcon };
