import jwtDecode from 'jwt-decode';
import { HttpStatus } from '../constants/Constants';
import authService from '../services/AuthService';
import { axiosInstance, axiosAuthInstance } from './AxiosInstance';

const apiRequestInterceptor = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const access_token = localStorage.getItem('access_token');
      const refresh_token = localStorage.getItem('refresh_token');
      const remember_me = localStorage.getItem('remember_me');
      if (refresh_token) {
        if (jwtDecode(access_token).exp * 1000 < new Date().getTime()) {
          const requestData = {
            refreshToken: `${refresh_token}`,
          };
          const response = await authService.refreshTokens(requestData);
          if (response.status === 200) {
            localStorage.clear();
            localStorage.setItem('refresh_token', response.data.refresh_token);
            localStorage.setItem('access_token', response.data.access_token);
            if (remember_me) {
              localStorage.setItem('remember_me', 'true');
            }
          }
        }
      }
      const validAccessToken = localStorage.getItem('access_token');
      if (validAccessToken) {
        config.headers.Authorization = `Bearer ${validAccessToken}`;
      }
      return config;
    },
    (error) => error
  );
};

let refresh = false;
const apiResponseInterceptor = ({
  setIsLimitReached,
  setLimitExceedMessage,
  setIsAuthenticated,
}) => {
  axiosInstance.interceptors.response.use(
    (resp) => resp,
    async (error) => {
      const refresh_token = localStorage.getItem('refresh_token');
      const remember_me = localStorage.getItem('remember_me');
      if (error.response?.status === HttpStatus.UN_AVAILABLE) {
        setIsLimitReached(true);
        if (error.response?.data.error_msg) {
          setLimitExceedMessage(error.response?.data.error_msg);
        }
        if (error.response?.data.message) {
          setLimitExceedMessage(error.response?.data.message);
        }
      } else {
        setIsLimitReached(false);
      }
      if (refresh_token) {
        if (error.response?.status === 401 && !refresh) {
          refresh = true;
          const requestData = {
            refreshToken: refresh_token,
          };
          const response = await authService.refreshTokens(requestData);
          if (response.status === 200) {
            localStorage.clear();
            localStorage.setItem('refresh_token', response.data.refresh_token);
            localStorage.setItem('access_token', response.data.access_token);
            if (remember_me) localStorage.setItem('remember_me', 'true');
            return axiosInstance(error.config);
          }
          setIsAuthenticated(false);
        }
      }

      refresh = false;
      if (error.response) {
        return error.response;
      }
      return error;
    }
  );
};

const apiAuthResponseInterceptor = ({
  setIsLimitReached,
  setLimitExceedMessage,
}) => {
  axiosAuthInstance.interceptors.response.use(
    (resp) => resp,
    async (error) => {
      if (error.response?.status === HttpStatus.UN_AVAILABLE) {
        setIsLimitReached(true);
        if (error.response?.data.error_msg) {
          setLimitExceedMessage(error.response?.data.error_msg);
        }
        if (error.response?.data.message) {
          setLimitExceedMessage(error.response?.data.message);
        }
      } else {
        setIsLimitReached(false);
      }
      if (error.response) {
        return error.response;
      }
      return error;
    }
  );
};

export {
  apiResponseInterceptor,
  apiRequestInterceptor,
  apiAuthResponseInterceptor,
};
