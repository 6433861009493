import { useContext } from 'react';
import RateLimitContext from '../contexts/RateLimitContext';
import { assertContextScope } from '../utils/ContextUtils';

const useRateLimt = () => {
  const context = useContext(RateLimitContext);
  assertContextScope(context, 'RateLimitContext', 'useRateLimit');
  return {
    isLimitReached: context[0],
    setIsLimitReached: context[1],
    limitExceedMessage: context[2],
    setLimitExceedMessage: context[3],
  };
};

export default useRateLimt;
