import React from 'react';
import { ReactChildrenType, LoadingContextType } from '../../types';
import LoadingContext from '../LoadingContext';

interface ILoadingContextProviderProps {
  value: LoadingContextType;
  children: ReactChildrenType;
}

const AuthContextProvider: React.FC<ILoadingContextProviderProps> = (
  props: ILoadingContextProviderProps
) => {
  const { value, children } = props;
  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
};

export default AuthContextProvider;
