import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import RateLimitProvider from './contexts/providers/RateLimitProvider';
import { RateLimitContextType } from './types';

const AppWrapper = () => {
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [limitExceedMessage, setLimitExceedMessage] = useState('');

  const rateLimitContextData: RateLimitContextType = [
    isLimitReached,
    setIsLimitReached,
    limitExceedMessage,
    setLimitExceedMessage,
  ];

  return (
    <RateLimitProvider value={rateLimitContextData}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </RateLimitProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppWrapper />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
