import { createContext } from 'react';
import { AuthContextType } from '../types';

const AuthContext = createContext<AuthContextType>([
  false,
  null,
  null,
  false,
  {},
  null,
  true,
  null,
]);

export default AuthContext;
