import React, { useState, useCallback, useEffect } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { StyledEngineProvider } from '@mui/material';

import Routes from './Routes';
import keycloak from './keycloak';
import './App.scss';

import {
  apiResponseInterceptor,
  apiRequestInterceptor,
  apiAuthResponseInterceptor,
} from './configs/AxiosInterceptors';

import { AuthContextType, LoadingContextType } from './types';
import AuthContextProvider from './contexts/providers/AuthContextProvider';
import LoadingContextProvider from './contexts/providers/LoadingContextProvider';
import authService from './services/AuthService';
import NavBar from './components/nav-bar/NavBar';
import MuiTheme from './themes/MuiTheme';
import Footer from './components/footer/Footer';
import ScrollTop from './components/scroll-top/ScrollTop';
import {
  DialogType,
  HttpStatus,
  LimitReachedTitle,
} from './constants/Constants';
import useRateLimt from './hooks/UseRateLimit';
import WarningDialog from './components/warning-dialog/WarningDialog';
import Loading from './components/loading/Loading';

interface IAppProps {}

const App: React.FC<IAppProps> = () => {
  const [isAuthInProgress, setAuthProgress] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [useSSO, setUseSSO] = useState<boolean>(true);

  const {
    isLimitReached,
    setIsLimitReached,
    limitExceedMessage,
    setLimitExceedMessage,
  } = useRateLimt();

  apiRequestInterceptor();
  apiResponseInterceptor({
    setIsLimitReached,
    setLimitExceedMessage,
    setIsAuthenticated,
  });
  apiAuthResponseInterceptor({
    setIsLimitReached,
    setLimitExceedMessage,
  });

  const onKcAuthSuccess = async (event, error) => {
    if (event === 'onInitError') {
      console.log('Error on init', error);
      setUseSSO(false);
    } else if (event === 'onAuthSuccess') {
      if (keycloak.authenticated) {
        authService.setTokens(
          {
            access_token: keycloak.token,
            refresh_token: keycloak.refreshToken,
          },
          true
        );
        const response = await authService.authenticatedUser();
        if (response.status === HttpStatus.UN_AUTHORIZED) {
          setIsAuthenticated(false);
        }
        if (
          response.status === HttpStatus.UN_AVAILABLE &&
          localStorage.getItem('access_token') &&
          localStorage.getItem('username')
        ) {
          setIsAuthenticated(true);
        }
        if (response?.status === HttpStatus.OK) {
          const user = response.data;
          if (user?.username) {
            localStorage.setItem('username', user.username);
            setIsAuthenticated(!!user.username);
            setUserDetails({
              ...userDetails,
              username: user.username,
              email: user.email,
            });
          }
        }
      } else {
        setIsAuthenticated(true);
        console.log('Authentication set complete');
        window.location.reload();
      }
    } else {
      loadSession();
    }
  };

  const loadSession = useCallback(async (): Promise<void> => {
    try {
      authService.loginListnerForAllTabs();
      const response = await authService.authenticatedUser();
      if (response.status === HttpStatus.UN_AUTHORIZED) {
        setIsAuthenticated(false);
      }
      if (
        response.status === HttpStatus.UN_AVAILABLE &&
        localStorage.getItem('access_token') &&
        localStorage.getItem('username')
      ) {
        setIsAuthenticated(true);
      }

      if (response?.status === HttpStatus.OK) {
        const user = response.data;
        if (user?.username) {
          localStorage.setItem('username', user.username);
          setIsAuthenticated(!!user.username);
        }
      }
    } catch (e) {
      console.log(e);
    }
    setAuthProgress(false);
  }, []);

  useEffect(() => {
    loadSession();
  }, [loadSession]);

  const authContextData: AuthContextType = [
    isAuthenticated,
    setIsAuthenticated,
    setAuthProgress,
    isAuthInProgress,
    userDetails,
    setUserDetails,
    useSSO,
    setUseSSO,
  ];

  const loadingContextData: LoadingContextType = [isLoading, setIsLoading];

  return (
    <>
      {useSSO ? (
        <ReactKeycloakProvider
          authClient={keycloak}
          LoadingComponent={<Loading />}
          onEvent={onKcAuthSuccess}
        >
          <MuiTheme>
            {!isAuthInProgress && (
              <StyledEngineProvider injectFirst>
                <ScrollTop />
                <div className="App" id="page-container" data-testid="app">
                  <AuthContextProvider value={authContextData}>
                    <LoadingContextProvider value={loadingContextData}>
                      <WarningDialog
                        open={isLimitReached}
                        handleClose={() => setIsLimitReached(false)}
                        handleConfirm={() => setIsLimitReached(false)}
                        title={LimitReachedTitle}
                        message={limitExceedMessage}
                        type={DialogType.LIMIT_EXCEEDED}
                        cancelText="close"
                      />
                      <div id="content-wrap">
                        <NavBar />
                        <Routes />
                      </div>
                      <footer id="footer">
                        <Footer />
                      </footer>
                    </LoadingContextProvider>
                  </AuthContextProvider>
                </div>
              </StyledEngineProvider>
            )}
          </MuiTheme>
        </ReactKeycloakProvider>
      ) : (
        <MuiTheme>
          {!isAuthInProgress && (
            <StyledEngineProvider injectFirst>
              <ScrollTop />
              <div className="App" id="page-container" data-testid="app">
                <AuthContextProvider value={authContextData}>
                  <LoadingContextProvider value={loadingContextData}>
                    <WarningDialog
                      open={isLimitReached}
                      handleClose={() => setIsLimitReached(false)}
                      handleConfirm={() => setIsLimitReached(false)}
                      title={LimitReachedTitle}
                      message={limitExceedMessage}
                      type={DialogType.LIMIT_EXCEEDED}
                      cancelText="close"
                    />
                    <div id="content-wrap">
                      <NavBar />
                      <Routes />
                    </div>
                    <footer id="footer">
                      <Footer />
                    </footer>
                  </LoadingContextProvider>
                </AuthContextProvider>
              </div>
            </StyledEngineProvider>
          )}
        </MuiTheme>
      )}
    </>
  );
};

export default App;
