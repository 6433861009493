import { styled, Button } from '@mui/material';

interface ISubmitButtonInputProps {
  width?: number;
  height?: number;
  ml?: number;
}

const SubmitButton = styled(Button)<ISubmitButtonInputProps>(
  ({ theme, width, height }) => ({
    borderRadius: '52px',
    backgroundColor: theme.colors.appBlue,
    width: width ? `${width}px` : '124px',
    height: height ? `${height}px` : '37px',
    textTransform: 'none',
    ':hover': {
      backgroundColor: theme.colors.appBlue,
    },
    ':disabled': {
      backgroundColor: '#ccc',
      color: theme.colors.appBlue,
    },
  })
);

const HomeNavigateButton = styled(Button)<ISubmitButtonInputProps>(
  ({ theme, width, height }) => ({
    borderRadius: '52px',
    backgroundColor: theme.colors.appSky100,
    color: theme.colors.appBlue,
    width: width ? `${width}px` : '124px',
    height: height ? `${height}px` : '37px',
    textTransform: 'none',
  })
);

interface INavButtonProps {
  underline?: number;
}

const NavButton = styled(Button)<INavButtonProps>(({ theme, underline }) => ({
  color: theme.colors.blurredWhite,
  fontSize: '12px',
  lineHeight: '14.52px',
  fontWeight: '400',
  cursor: 'default',
  textTransform: 'none',
  marginTop: '17px',
  padding: underline ? '6px 8px 25px 8px' : '6px 8px 30px 8px',
  borderBottom: underline ? `5px solid ${theme.colors.appWhite}` : 'none',
  borderRadius: '0px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const RegisterButton = styled(SubmitButton)(({ theme }) => ({
  fontSize: '12px',
  backgroundColor: theme.colors.appWhite,
  marginLeft: '15px',
  marginRight: '6px',
  fontWeight: '400',
  color: theme.colors.appBlue,
  ':hover': {
    backgroundColor: theme.colors.appWhite,
  },
}));

const SmallWhiteButton = styled(Button)<ISubmitButtonInputProps>(
  ({ theme, width, height }) => ({
    borderRadius: '48px',
    border: `1px solid ${theme.colors.appFormDivider}`,
    backgroundColor: theme.colors.appWhite,
    color: theme.colors.appBlue,
    width: width ? `${width}px` : '118px',
    height: height ? `${height}px` : '37px',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '15px',
    textTransform: 'none',
  })
);

const WhiteButton = styled(Button)<ISubmitButtonInputProps>(
  ({ theme, width, height, ml }) => ({
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '17px',
    padding: '11px 24px',
    width: width ? `${width}px` : '124px',
    height: height ? `${height}px` : '37px',
    backgroundColor: theme.colors.appWhite,
    color: theme.colors.appFormDivider,
    border: `1px solid ${theme.colors.appFormDivider}`,
    borderRadius: '52px',
    marginLeft: ml ? `${ml}px` : '15px',
    marginRight: '6px',
  })
);

export {
  SubmitButton,
  NavButton,
  RegisterButton,
  SmallWhiteButton,
  WhiteButton,
  HomeNavigateButton,
};
