import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
// import { LoadCanvasTemplateNoReload } from 'react-simple-captcha';

const Title = styled('h1')(({ theme }) => ({
  fontSize: '23px',
  fontWeight: '800',
  lineHeight: '36px',
}));
const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const FormDescription = styled('p')(({ theme }) => ({
  color: theme.colors.appCardContent,
  fontSize: '12px',
  lineHeight: '15px',
  fontWeight: '400px',
  marginTop: '5px',
  marginBottom: '24px',
}));

const FormCard = styled(Container)(({ theme }) => ({
  border: `1px solid ${theme.colors.appFormDivider}`,
  borderRadius: '8px',
  width: '100%',
  alignItems: 'flex-start',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.colors.appCardContent,
  flexDirection: 'column',
  padding: '20px',
  marginBottom: '12px',
  '@media (max-width: 1500px)': {
    fontSize: '13px',
    lineHeight: '17px',
  },
  '@media (max-width: 1410px)': {
    fontSize: '12px',
    lineHeight: '15px',
  },
}));

const BpCheckboxIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckboxCheckedIcon = styled(BpCheckboxIcon)(({ theme }) => ({
  backgroundColor: theme.colors.appBlue,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: theme.colors.appBlue,
  },
}));

const BpRadioIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow: `inset 0 0 0 1px ${theme.colors.appCardBorder}, inset 0 -1px 0 ${theme.colors.appCardBorder}`,
  backgroundColor: theme.colors.appWhite,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

const BpRadioCheckedIcon = styled(BpRadioIcon)(({ theme }) => ({
  backgroundColor: theme.colors.appBlue,
  boxShadow: `inset 0 0 0 1px ${theme.colors.appBlue}, inset 0 -1px 0 ${theme.colors.appBlue}`,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: `radial-gradient(${theme.colors.appWhite},${theme.colors.appWhite} 28%,transparent 32%)`,
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: theme.colors.appBlue,
  },
}));

const WbRadioIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow: `inset 0 0 0 1px ${theme.colors.appCardBorder}, inset 0 -1px 0 ${theme.colors.appCardBorder}`,
  backgroundColor: theme.colors.appWhite,
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

const WbRadioCheckedIcon = styled(BpRadioIcon)(({ theme }) => ({
  backgroundColor: theme.colors.appWhite,
  boxShadow: `inset 0 0 0 1px ${theme.colors.appWhite}, inset 0 -1px 0 ${theme.colors.appWhite}`,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: `radial-gradient(${theme.colors.appBlue},${theme.colors.appBlue} 28%,transparent 32%)`,
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: theme.colors.appWhite,
  },
}));

const FormFooterDescription = styled(FormDescription)(({ theme }) => ({
  // color: theme.colors.appCardContent,
  // fontSize: "12px",
  // lineHeight: "15px",
  // fontWeight: "400"
  margin: '12px 0',
}));
const TitleContainer = styled('div')(({ theme }) => ({
  margin: '24px 0',
}));

const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: '61px',
}));

const Form = styled('div')(({ theme }) => ({
  width: '348px',
  borderBottom: `1px solid ${theme.colors.appFormDivider}`,
  paddingBottom: '24px',
}));

const InputContainer = styled('div')(({ theme }) => ({
  margin: '24px 0',
}));

const Label = styled('label')(({ theme }) => ({
  marginBottom: '4px',
  fontSize: '12px',
  lineHeight: '20px',
  fontWeight: '500',
  color: theme.colors.appInputLabel,
}));

const InputSpan = styled('span')(({ theme }) => ({
  color: theme.colors.appErrorIconColor,
}));

const CustomCaptcha = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

const Input = styled('input')(
  ({ theme, width = '100%', borderRadius = '6px' }) => ({
    width,
    height: '38px',
    fontSize: '14px',
    padding: '9px 13px',
    border: `1px solid ${theme.colors.appInputBorder}`,
    borderRadius,
  })
);

const FormFooter = styled('div')(({ theme }) => ({
  margin: '24px 0',
}));

const FormFooterLinks = styled(Link)(({ theme }) => ({
  color: theme.colors.appBlue,
  cursor: 'pointer',
}));

const Tabs = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  '&.tabs': {
    marginBottom: '33px',
  },
  '& .tab': {
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '16px',
    color: theme.colors.appInputLabel,
    marginRight: '33px',
    cursor: 'pointer',
  },
  '& .tab.selected': {
    padding: '11px 24px',
    border: `1px solid ${theme.colors.appBlue}`,
    borderRadius: '40px',
    color: theme.colors.appWhite,
    backgroundColor: theme.colors.appBlue,
    cursor: 'pointer',
  },
}));

const Content = styled('div')(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  '& div': {
    marginBottom: '20px',
    boxSizing: 'border-box',
    '& ol': {
      listStyleType: 'decimal',
      margin: 0,
      padding: 0,
    },
    '& ul': {
      listStyleType: 'disc',
      margin: 0,
      padding: 0,
    },
  },
  '& h2': {
    color: theme.colors.appTitle,
    fontWeight: '600',
    fontStyle: 'bold',
    margin: '10px 0',
  },
  '& h3': {
    color: theme.colors.appTitle,
    fontWeight: '500',
    fontStyle: 'bold',
    margin: '10px 0',
  },
  '& li': {
    color: theme.colors.appCardContent,
    fontSize: '14px',
    fontWeight: '400',
    marginLeft: '1rem',
  },
  '& p': {
    color: theme.colors.appCardContent,
    fontSize: '14px',
    fontWeight: '400',
    marginBottom: '15px',
    '& .citation': {
      marginBottom: 0,
    },
    '& a': {
      textDecoration: 'underline',
      color: theme.colors.appBlue,
    },
  },
}));

export {
  Form,
  Input,
  Label,
  InputContainer,
  FormContainer,
  Title,
  FormDescription,
  TitleContainer,
  FormFooter,
  FormFooterDescription,
  FormFooterLinks,
  Container,
  InputSpan,
  CustomCaptcha,
  FormCard,
  BpCheckboxCheckedIcon,
  BpCheckboxIcon,
  BpRadioCheckedIcon,
  BpRadioIcon,
  WbRadioIcon,
  WbRadioCheckedIcon,
  Tabs,
  Content,
};
