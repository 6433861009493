import React from 'react';
import { ReactChildrenType, RateLimitContextType } from '../../types';
import RateLimitContext from '../RateLimitContext';

interface IRateLimitProviderProps {
  value: RateLimitContextType;
  children: ReactChildrenType;
}

const RateLimitProvider: React.FC<IRateLimitProviderProps> = (
  props: IRateLimitProviderProps
) => {
  const { value, children } = props;
  return (
    <RateLimitContext.Provider value={value}>
      {children}
    </RateLimitContext.Provider>
  );
};

export default RateLimitProvider;
